import { UseToastOptions } from '@chakra-ui/react';

type IToast = {
  title: string;
  description: string;
};

export function successToast({ title, description }: IToast): UseToastOptions {
  return {
    title,
    description,
    status: 'success',
    duration: 5000,
    isClosable: true,
    position: 'top-right',
  };
}

export function errorToast({ title, description }: IToast): UseToastOptions {
  return {
    title,
    description,
    status: 'error',
    duration: 5000,
    isClosable: true,
    position: 'top-right',
  };
}
