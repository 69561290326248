/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import { useToast } from '@chakra-ui/react';
import Router from 'next/router';

import { api } from '../services/api/clientApi';
import { errorToast, successToast } from '../utils/toastFeedbacks';

type User = {
  id: string;
  email: string;
  name: string;
  permissions: string[];
  roles: string[];
};

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  signIn: (credentials: SignInCredentials) => Promise<void>;
  signOut: () => void;
  userData: User;
  isAuthenticated: boolean;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function signOut() {
  destroyCookie(undefined, 'buscarrural.token');
  destroyCookie(undefined, 'buscarrural.refreshToken');

  Router.push('/');
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [userData, setUserData] = useState<User>();
  const isAuthenticated = !!userData;
  const toast = useToast();

  useEffect(() => {
    const { 'buscarrural.token': token } = parseCookies();

    if (token) {
      api
        .get('users/me')
        .then(response => {
          const userDataInfo = response.data;

          setUserData(userDataInfo[0].user);
        })
        .catch(() => {
          signOut();
        });
    } else {
      Router.replace('/');
    }
  }, []);

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post('sessions/admin', {
        email,
        password,
      });

      const { access_token, refresh_token, user } = response.data;

      setCookie(undefined, 'buscarrural.token', access_token, {
        maxAge: 60 * 60 * 24 * 30, // 30 Days
        path: '/',
      });

      setCookie(undefined, 'buscarrural.refreshToken', refresh_token, {
        maxAge: 60 * 60 * 24 * 30, // 30 Days
        path: '/',
      });

      api.defaults.headers.Authorization = `Bearer ${access_token}`;

      setUserData(user);

      Router.push('/dashboard');

      toast(
        successToast({
          title: 'Sucesso.',
          description: 'Usuário autenticado com sucesso.',
        }),
      );
    } catch {
      toast(
        errorToast({
          title: 'Erro.',
          description: 'Erro ao realizar a autenticação.',
        }),
      );
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, isAuthenticated, userData }}
    >
      {children}
    </AuthContext.Provider>
  );
}
